const skuDecoder = ({ codifiedSKU = '', skuDecoderData: { productSkus, products, distinct } }) => {
  const u_codifiedSKU = codifiedSKU.toUpperCase();

  if (!productSkus.nodes.map(({ _id }) => _id).includes(u_codifiedSKU) || !products) return ({
    skuDecoded: false,
    slugPrefix: null,
    skuSuffix: null
  })

  let product = products.nodes.find(product => {
    const weights = product.searchWeighting?.split(',');
    return weights?.some(weight => u_codifiedSKU.includes(weight));
  });

  if (!product) {
    product = products.nodes.filter(product => !u_codifiedSKU.indexOf(product.partial_sku))[0]
  }

  
  const skuSuffix = u_codifiedSKU.replace(`${product.partial_sku}-`, '')
  const skuOptions = skuSuffix.split('-')
  const lang = JSON.parse(product.lang)
  const optionValues = product.productOptionList
  const skuOptionsMap = skuOptions.reduce((options, option, idx) => {
    return { ...options, [option]: optionValues[option] }
  }, {})

  return {
    skuDecoded: true,
    slugPrefix: product.slugPrefix.current,
    skuSuffix,
  }
}

export default skuDecoder
