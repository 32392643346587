import React from 'react';
import { navigate } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

const SearchSuggestions = () => {
  const searchSuggestionData = useStaticQuery(
    graphql`
      query {
        allSanitySearch {
          edges {
            node {
              searchTerms
            }
          }
        }
      }
    `,
  );

  const terms = searchSuggestionData.allSanitySearch.edges[0].node.searchTerms;

  return (
    <div className="lg:flex lg:items-center">
      <div className="type-upper-240 mb-2 lg:mb-0 mr-6">Suggestions</div>
      <ul className="flex flex-wrap">
        {terms.map((query, i) => (
          <li key={query} className="mr-1 type-sans-430 whitespace-nowrap">
            <a
              href="#"
              className="no-underline-link inline-block"
              onClick={(event) => {
                event.preventDefault();
                navigate(`/search?query=${query}`);
              }}
            >
              {query}
              {terms.length != i + 1 ? ',' : ''}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchSuggestions;
