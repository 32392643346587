import { searchDL } from '@src/helpers/dataLayerHelper';
import React from 'react';
import { SearchBox } from 'react-instantsearch-dom';

const ProductSearchBox = () => {
  const handleBlur = (event) => {
    if (event.target.value) {
      searchDL(event.target.value)
    }
  };

  return (
    <div className="wrapper pt-12">
      <SearchBox
        autoFocus
        submit={false}
        reset="Clear"
        translations={{
          placeholder: 'Search a product',
        }}
        inputId="mainSearchBox"
        onBlur={handleBlur}  // Add the onBlur event listener here
      />
    </div>
  );
};

export default ProductSearchBox;
