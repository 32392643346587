import algoliasearch from 'algoliasearch/lite';

const isSku = async ({ query }) => {
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  );

  searchClient.initIndex(`${process.env.GATSBY_ALGOLIA_PREFIX}global`);

  const searchOptions = {
    getRankingInfo: false,
    analytics: false,
    enableABTest: false,
    hitsPerPage: 1000,
    attributesToRetrieve: [
      "partial_sku"
    ],
    attributesToSnippet: [],
    responseFields: "*",
    explain: "",
    attributesToHighlight: [],
    page: 0,
    facets: []
  }

  return searchClient.search([{
    indexName: `${process.env.GATSBY_ALGOLIA_PREFIX}global`,
    params: searchOptions,
    query: '*'
  }], {
    cacheable: true
  }).then(({ results }) => results[0]
    .hits
    .flatMap(({ partial_sku }) => partial_sku)
    .filter((partial_sku) => !query.indexOf(partial_sku))[0]
  ).catch(err => {
    console.error({ err })
    return ''
  });
}

export default isSku